/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    blockquote: "blockquote",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "I'm a big fan of G2."), "\n", React.createElement(_components.p, null, "If you're unfamiliar, G2 is the world's largest business software review site. It's a powerful resource, with over 1.8 million real user reviews to help software buyers make informed decisions."), "\n", React.createElement(_components.p, null, "One of the best parts: G2's research team keeps a steady finger on the pulse of the SaaS industry. When new technologies emerge, they create new product categories on their site to help people discover and learn about new solutions. This is especially important when new types of solutions emerge from broader categories to better serve a particular audience or use case."), "\n", React.createElement(_components.p, null, "That's exactly why G2 recently established a new category for ", React.createElement(_components.a, {
    href: "https://www.g2.com/categories/embedded-integration-platforms",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "embedded integration platforms"), " (embedded iPaaS). They explained:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "\"As integration platforms as a service (iPaaS) continue to rise in popularity, developers and product teams are looking for a new tool to cover areas traditional iPaaS might not….While traditional iPaaS platforms focus on connecting and automating internal functions, embedded integration platforms allow software providers to build and deliver integrations for their end users.\""), "\n"), "\n", React.createElement(_components.p, null, "Here at Prismatic, we're passionate about educating SaaS teams on the value of enhancing their products with in-app integrations and how embedded iPaaS can help."), "\n", React.createElement(_components.p, null, "Since day one, we've built our platform on the premise that SaaS teams need a fundamentally different type of integration platform to solve the unique challenges of delivering productized customer-facing integrations."), "\n", React.createElement(_components.p, null, "That's why I collaborated with G2 this summer to publish a piece on their Learn Hub titled ", React.createElement(_components.a, {
    href: "https://learn.g2.com/embedded-ipaas",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "\"What Is an Embedded iPaaS? How It Improves Customer Experience.\"")), "\n", React.createElement(_components.p, null, "It's a great overview of questions like:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "What makes integrations so difficult for SaaS teams?"), "\n", React.createElement(_components.li, null, "What is an embedded iPaaS and how is it different from traditional iPaaS?"), "\n", React.createElement(_components.li, null, "What are the benefits of embedded integration platforms?"), "\n", React.createElement(_components.li, null, "How do you choose the best one for your team?"), "\n"), "\n", React.createElement(_components.p, null, "Check it out ", React.createElement(_components.a, {
    href: "https://learn.g2.com/embedded-ipaas",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "here"), ". Then, if you want to learn more about how Prismatic's ", React.createElement(_components.a, {
    href: "/resources/benefits-embedded-integration-platforms-for-saas-products/"
  }, "embedded integration platform"), " can make integrations easier for your own SaaS, ", React.createElement(_components.a, {
    href: "/contact/"
  }, "just reach out"), ". We'd love to help."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
